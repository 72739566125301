<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div>
    <b-card title="EAPI Policies">
      <p class="d-inline">This page shows all EAPI policies. You can manage policies from this page.</p>
      <b-btn variant="success" class="float-right" @click="$bvModal.show('modal-create-eapi-policy')">Create new policy</b-btn>
    </b-card>
    <b-card no-body>
      <div class="flex-row p-2">

        <b-input-group-append class="d-inline-flex w-50">
           <model-list-select v-model="filters.enterprise_uid"
                              :list="enterpriseOptions"
                              option-value="uid"
                              option-text="display_name"
                              placeholder="Select Enterprise"
                              @input="refreshPolicies"
           />
          <b-button @click="clearEnterpriseFilter" class="ml-1">Clear</b-button>
        </b-input-group-append>
        <b-btn variant="primary" class="float-right" @click="$bvModal.show('modal-bulk-update-eapi-policy')">Bulk update</b-btn>
      </div>

      <b-table
        class="data-table pb-0"
        :items="policies"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
        hover
      >
        <template #cell(description)="data">
          <div @mouseover="expandDescription(data.item.id)" @mouseleave="shrinkDescription">
            {{ showDescription(data.item.id, data.item.description) }}
          </div>
        </template>

        <template #cell(enterprise_uid)="data">
          {{ enterpriseName(data.item.enterprise_uid) }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn"
                                    v-if="$can('Update', 'Policy')"
                                    @click="showPolicyModal(data.item, 'modal-update-eapi-policy')">
              Update
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn"
                                    v-if="$can('Delete', 'Policy')"
                                    @click="showPolicyModal(data.item, 'modal-delete-eapi-policy')">
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <b-row>
        <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
          <b-pagination @change="getMorePolicies" :total-rows="total_count" :per-page="filters.page_size"
                        v-model="currentPage" class="my-0"/>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal-create-eapi-policy" title="Create policy" hide-footer>
      <create-eapi-policy @refresh="refreshPolicies" @close="closeModals()"></create-eapi-policy>
    </b-modal>

    <b-modal id="modal-update-eapi-policy" title="Update policy" hide-footer>
      <update-eapi-policy :existing-policy="selectedPolicy" @refresh="refreshPolicies" @close="closeModals()"></update-eapi-policy>
    </b-modal>

    <b-modal id="modal-delete-eapi-policy" title="Delete policy" hide-footer>
      <delete-modal
        v-if="selectedPolicy"
        @close="closeModals()" @delete="deletePolicy(selectedPolicy.id)"
        :subtitle="selectedPolicy.name"
        title="Are you sure you wish to delete this EAPI policy"></delete-modal>
    </b-modal>


    <b-modal id="modal-bulk-update-eapi-policy" title="Bulk update policies" hide-footer>
      <bulk-update-eapi-policy @refresh="refreshPolicies" @close="closeModals()"></bulk-update-eapi-policy>
    </b-modal>
  </div>

</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelListSelect } from 'vue-search-select';
import EapiPolicyService from '@/services/EapiPolicyService'
import CreateEapiPolicy from '@/views/policies/sections/CreateEapiPolicy.vue';
import UpdateEapiPolicy from '@/views/policies/sections/UpdateEapiPolicy.vue';
import EnterpriseService from '@/services/EnterpriseService';
import BulkUpdateEapiPolicy from '@/views/policies/sections/BulkUpdateEapiPolicy.vue';
import helperService from '@/services/HelperService';
import DeleteModal from '../../components/modals/DeleteModal.vue'

export default {
  name: 'EapiPolicies',
  components: {
    CreateEapiPolicy,
    UpdateEapiPolicy,
    BulkUpdateEapiPolicy,
    DeleteModal,
    ModelListSelect,
  },
  data() {
    return {
      selectedPolicy: null,
      policies: [],
      enterpriseOptions: [],
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'policy.filename',
          label: 'Application',
        },
        {
          key: 'enterprise_uid',
          label: 'Enterprise UID',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      showingDescription: 0,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: '',
      },
      total_count: 0,
    }
  },
  mounted() {
    this.getPolicies()
    this.getAllEnterprises()
  },
  methods: {
    async getPolicies() {
      try {
        const res = await EapiPolicyService.getPolicies(this.filters)
        this.policies = res.data.data
        this.filters.page = res.data.meta.page
        this.filters.page_size = res.data.meta.page_size
        this.total_count = res.data.meta.total_count
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get policies, please refresh and try again')
      }
    },
    getMorePolicies(val) {
      this.filters.page = val - 1
      this.getPolicies()
    },
    async deletePolicy(id) {
      try {
        await EapiPolicyService.deletePolicy(id)
        helperService.showNotfySuccess(this.$toast, `Deleted policy ${this.selectedPolicy.name} Successfully`)
        this.refreshPolicies()
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not delete policy, please refresh and try again')
      }
    },
    refreshPolicies() {
      this.closeModals()
      this.getPolicies()
    },
    async getAllEnterprises() {
      try {
        const res = await EnterpriseService.getEnterprises({page_size: 100})
        const enterprises = res.data.data
        enterprises.forEach(val => {
          this.enterpriseOptions.push(val)
        })
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get enterprises, please refresh and try again')
      }
    },
    closeModals() {
      this.$bvModal.hide('modal-create-eapi-policy')
      this.$bvModal.hide('modal-update-eapi-policy')
      this.$bvModal.hide('modal-delete-eapi-policy')
      this.$bvModal.hide('modal-bulk-update-eapi-policy')
    },
    setSelectedPolicy(policy) {
      this.selectedPolicy = policy
    },
    showPolicyModal(policy, modalName) {
      this.setSelectedPolicy(policy)
      this.$bvModal.show(modalName)
    },
    clearEnterpriseFilter() {
      this.filters.enterprise_uid = ''
      this.getPolicies()
    },
    showDescription(id, description) {
      if (description.length > 50 && this.showingDescription !== id) {
        return `${description.slice(0, 47)}...`
      }
      return description
    },
    expandDescription(id) {
      this.showingDescription = id
    },
    shrinkDescription() {
      this.showingDescription = 0
    },
    enterpriseName(uid) {
      if (this.enterpriseOptions.length > 0) {
        const result = this.enterpriseOptions.find(val => val.uid === uid)
        return result?.name
      }
      return ''
    },
  },
}
</script>

<style scoped>

</style>
