<template>
  <div class="bulk-update-policy-modal">
    <div>
      <b-form-group>

        <b-input-group class="d-flex flex-column mb-1">
          <div>
            <label for="old-policy-in">Choose the policy you wish to update</label>
            <b-input-group-append>
              <model-list-select v-model="updatePolicies.old_filename"
                                 :list="policies"
                                 option-value="filename"
                                 option-text="display_name"
                                 placeholder="Select a policy"
              />
              <b-button @click="clearFilter">Clear</b-button>
            </b-input-group-append>
          </div>
        </b-input-group>

        <b-input-group class="d-flex flex-column">
          <div>
            <label for="new-policy-in">Please enter a new policy</label>
            <b-form-input id="new-policy-in" v-model="updatePolicies.new_filename" placeholder="Filename..." />
          </div>
        </b-input-group>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button variant="primary" :class="{'disabled': !checkFormValid()}" @click="updatePolicyFilenames()">Update filenames</b-button>
        </section>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import EapiPolicyService from '@/services/EapiPolicyService';
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelListSelect } from 'vue-search-select';
import helperService from '@/services/HelperService';

export default {
  name: 'BulkUpdateEapiPolicy',
  components: {
    ModelListSelect,
  },
  data() {
    return {
      policies: [],
      updatePolicies: {
        old_filename: '',
        new_filename: '',
      },
    }
  },
  mounted() {
    this.getPolicyFilenames()
  },
  methods: {
    async getPolicyFilenames() {
      try {
        const res = await EapiPolicyService.getPolicyFilenames()
        this.policies = res.data.data
        // Select Model component needs 2 different value for the clear function to work as intended
        // Adding a 'display_name' field with a space on the end
        this.policies.forEach(val => {
          // eslint-disable-next-line no-param-reassign
          val.display_name = `${val.filename} `
        })
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get policy filenames, please refresh and try again')
      }
    },
    async updatePolicyFilenames() {
      try {
        await EapiPolicyService.bulkUpdatePolicyFilenames(this.updatePolicies)
        helperService.showNotfySuccess(this.$toast, 'Updated policies successfully')
        this.$emit('refresh')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, 'Could not update policy filenames, please refresh and try again')
      }
    },
    clearFilter() {
      this.updatePolicies.old_filename = ''
    },
    checkFormValid() {
      const newFilename = this.updatePolicies.new_filename.length > 0;
      const oldFilename = this.updatePolicies.old_filename.length > 0;

      return newFilename && oldFilename;
    },
  },
}
</script>

<style scoped>

</style>
