<template>
  <div class="create-policy-modal">
    <div>
      <b-form-group>
        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="name-in">Name</label>
            <b-form-input id="name-in" v-model="policy.name" placeholder="Enter a name..." />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="description-in">Description</label>
            <b-form-textarea id="description-in" v-model="policy.description" rows="5" placeholder="And a description..." />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="enterprise_uid-in">Enterprise</label>
            <b-input-group-append>
              <model-list-select v-model="policy.enterprise_uid"
                                 :list="enterpriseOptions"
                                 option-value="uid"
                                 option-text="display_name"
                                 placeholder="Select Enterprise"
              />
              <b-button @click="clearEnterpriseFilter">Clear</b-button>
            </b-input-group-append>
          </div>

        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="filename-in">MPOS Filename</label>
            <b-form-input id="filename-in" v-model="policy.policy.filename" class="d-flex flex-column" placeholder="Filename..." />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="filename-in">Onboard Keyboard Enabled?</label>
            <b-select v-model="policy.policy.onboard" :options="onboardOptions" />
          </div>
        </b-input-group>

        <b-input-group class="d-flex flex-row-reverse">
          <b-btn :class="{'disabled': !checkFormValid()}" variant="primary" @click="createPolicy">Create Policy</b-btn>
          <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import EnterpriseService from '@/services/EnterpriseService';
import { ModelListSelect } from 'vue-search-select'
import EapiPolicyService from '@/services/EapiPolicyService';
import helperService from '@/services/HelperService';

export default {
  name: 'CreateEapiPolicy',
  components: {
    ModelListSelect,
  },
  data() {
    return {
      onboardOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' },
      ],
      policy: {
        name: '',
        description: '',
        enterprise_uid: '',
        policy: {
          s3_link: '',
          filename: '',
          onboard: false,
        },
      },
      enterpriseOptions: [],
      enterpriseFilter: {
        page_size: 10_000,
      },
    }
  },
  mounted() {
    this.getEnterpriseUids()
  },
  methods: {
    async getEnterpriseUids() {
      try {
        const res = await EnterpriseService.getEnterprises(this.enterpriseFilter)
        const enterprises = res.data.data
        enterprises.forEach(val => {
          this.enterpriseOptions.push(val)
        })
      } catch (err) {
        helperService.showNotfyErr(this.toast, err, 'Could not get enterprises, please refresh and try again')
      }
    },
    async createPolicy() {
      try {
        await EapiPolicyService.createPolicy(this.policy)
        helperService.showNotfySuccess(this.$toast, 'Added policy successfully')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get policies, please refresh and try again')
      } finally {
        this.$emit('refresh')
      }
    },
    clearEnterpriseFilter() {
      this.policy.enterprise_uid = ''
    },
    checkFormValid() {
      const name = this.policy.name.length > 0;
      const filename = this.policy.policy.filename.length > 0;
      const enterpriseUid = this.policy.enterprise_uid.length > 0;

      return name && filename && enterpriseUid;
    },
  },

}
</script>

<style scoped>

</style>
